import React from 'react';
import Q3NotFound from 'gatsby-theme-q3/src/pages/404';
import SEOHelmet from '../../components/SEOHelmet';

const NotFound = () => (
  <>
    <SEOHelmet title="Page not found" />
    <Q3NotFound />
  </>
);

export default NotFound;
